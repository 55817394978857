import React, { useState } from "react";
interface Props {
  children: string;
  sliceLength: number;
  textColor?: string;
}
const ReadMore = ({ children, sliceLength, textColor = '#0056A3' }: Props) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setIsReadMore(!isReadMore);
  };
  return (
    <span className="text">
      {isReadMore ? text.slice(0, sliceLength) : text}
      <span className="show-more" onClick={toggleReadMore} style={{ color: textColor, cursor: 'pointer', fontWeight: 500 }}>
        {isReadMore ? (<><br/> Show more</>) :(<><br/> Show less</>)}
      </span>
    </span>
  );
};
export default ReadMore;
