// Customizable Area Start
import React from "react";
import { styled, Theme, Paper, PaperProps } from "@material-ui/core";

interface InfoBarProps extends PaperProps {
  className?: string;
  children?: React.ReactNode;
  "data-test-id"?: string;
}

const StyledInfoBar = styled(Paper)<InfoBarProps>(({ theme }: { theme: Theme }) => ({
  '&.info-bar': {
    boxShadow: '0px 2px 8px 0px #00000029',
    borderRadius: '8px',
    border: '1px solid #EFF3FF',
    padding: '12px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    '& .info-bar-content': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      gap: 10,
      [theme.breakpoints.up('md')]: {
        width: '100%',
        flexDirection: 'row',
      },
      '& .info-bar__wrapper': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,
        '& .info-bar-icon': {
          width: 24,
          height: 24,
          [theme.breakpoints.up('md')]: {
            width: 16,
            height: 16,
          },
        },
        '& .info-bar-title': {
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '21px',
          [theme.breakpoints.up('md')]: {
            fontSize: '14px',
          },
        },
      },
      '& .info-bar-city': {
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '21px',
        [theme.breakpoints.up('md')]: {
          fontSize: '14px',
        },
      },
      '& .info-bar-value': {
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '21px',
        [theme.breakpoints.up('md')]: {
          fontSize: '14px',
        },
      },
    },
  }
}));

const InfoBar: React.FC<InfoBarProps> = (props) => {
  return <StyledInfoBar {...props} />;
};

export default InfoBar;
// Customizable Area End