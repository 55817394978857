// Customizable Area Start
import React from "react";
import { styled, Theme, Box, BoxProps } from "@material-ui/core";

interface CourseCardProps extends BoxProps {
  className?: string;
  children?: React.ReactNode;
  "data-test-id"?: string;
  onClick?: () => void;
}

const StyledCourseCard = styled(Box)<CourseCardProps>(({ theme }: { theme: Theme }) => ({
  '&.course-card': {
    display: 'flex',
    gap: 20,
    minHeight: '142px',
    flexDirection: 'column',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    '& .course-image__wrapper': {
      display: 'flex',
      width: '100%',
      height: '100%',
      [theme.breakpoints.up('xl')]: {
        maxWidth: 260,
      },
      borderRadius: '8px',
      '& .course-image': {
        borderRadius: '8px',
        width: '100%',
        height: '100%',
        objectFit: 'contain'
      }
    },
    '& .course-details': {
      width: '100%',
      '& .course-title__wrapper': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '& .course-title': {
          color: '#0056A3',
          fontFamily: 'Poppins',
          fontSize: '16px',
          fontWeight: 600,
          [theme.breakpoints.up('md')]: {
            fontSize: '24px',
          },
        },
      },
      '& .course-description__wrapper': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '& .course-description': {
          color: '#3B3B3B',
          fontSize: '12px',
          fontWeight: 400,
          [theme.breakpoints.up('md')]: {
            fontSize: '14px',
          },
          '& .show-more': {
            fontSize: '10px',
            [theme.breakpoints.up('md')]: {
              fontSize: '14px',
            },
          }
        }
      },
    }
  }
}));

const CourseCard: React.FC<CourseCardProps> = (props) => {
  return <StyledCourseCard {...props} />;
};

export default CourseCard;
// Customizable Area End