import React from "react";
// Customizable Area Start
import { styled, Theme, Paper, Grid, Box, Typography, LinearProgress, TableContainer, Table, TableCell, TableHead, TableBody, TableRow, Button } from "@material-ui/core";
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@material-ui/icons';
import { Value } from "react-calendar/dist/cjs/shared/types";
import DateCalendar from "react-calendar";
import SidebarLayout from "./SidebarLayout.web";
import Dashboard2Controller, { ChildDetails, CourseNavigationProps, CourseProps, InfoBarProps, Props, SchoolOngoingProgram, StudentReport, UpcomingEvents, YouMatterProps } from "./Dashboard2Controller";
import { profile, documentIcon, HapBeam, HapSpark, HapStar, ProgressStar, RewardsCollected, mpowerIcon, calendarArrowRight } from "./assets";
import { ProgramModal } from "./ProgramScheduler/ProgramModal.web";
import CommonSelect from "../../../components/src/commonSelect.web";
import CommonSnackBar from "../../../components/src/commonSnackBar.web";
import ReadMore from "../../../components/src/ReadMore";
import CourseCard from "./CourseCard.web";
import InfoBar from "./InfoBar.web";
const configJSON = require("./config.js");
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start

class Dashboard2 extends Dashboard2Controller {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);

    this.getUserDetails = this.getUserDetails.bind(this);
    this.getUpcomingEvents = this.getUpcomingEvents.bind(this);
    this.getUserProgress = this.getUserProgress.bind(this);
    this.getMindMastery = this.getMindMastery.bind(this);
    this.getUserSuperPowers = this.getUserSuperPowers.bind(this);
    this.getStudentReports = this.getStudentReports.bind(this);
    this.getTrainerOngoingPrograms = this.getTrainerOngoingPrograms.bind(this);
    this.getTrainerYouMatters = this.getTrainerYouMatters.bind(this);
    this.getChildrenDetails = this.getChildrenDetails.bind(this);
    this.getSchoolOngoingPrograms = this.getSchoolOngoingPrograms.bind(this);
    this.getSchoolOngoingProgramList = this.getSchoolOngoingProgramList.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleYouMatterFilterChange = this.handleYouMatterFilterChange.bind(this);
    this.handleClearYouMatterFilter = this.handleClearYouMatterFilter.bind(this);
    this.handleChildChange = this.handleChildChange.bind(this);
    this.handleEventData = this.handleEventData.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    this.getSortedTrainerYouMatter = this.getSortedTrainerYouMatter.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.switchPopup = this.switchPopup.bind(this);

  }

  async componentDidMount(): Promise<void> {
    const userRole = this.getUserRole();
    await this.getUserDetails();
    await this.getUpcomingEvents();
    if (userRole === 'student') {
      await this.getUserProgress();
      await this.getMindMastery();
      await this.getUserSuperPowers();
      await this.getStudentReports();
    }

    if (userRole === "trainer") {
      await this.getTrainerOngoingPrograms();
      await this.getTrainerYouMatters();
    }
    
    if (userRole === 'parents') {
      await this.getChildrenDetails();
    }

    if (userRole === 'school') {
      await this.getSchoolOngoingPrograms();
      await this.getSchoolOngoingProgramList();
    }
  }

  renderStudentDetails() {
    const userDetails = this.state.userDetails;
    const userFullName = `${userDetails.first_name} ${userDetails.last_name}`;
    const userProfilePicture = userDetails.image_url || profile;
    return (
      <Paper className="user-details" data-test-id="user-details">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} className="user-profile-picture__item">
            <Box className="user-profile-picture">
              <img alt={userFullName} src={userProfilePicture} />
            </Box>
          </Grid>
          <Grid item>
            <Box className="user-name">
              <Typography className="user-name__text" data-test-id="user-full-name">{userFullName}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  renderProgressBar() {
    const { progress } = this.state;
    return (
      <Paper className="user-progress" data-test-id="user-progress">
        <Box className="user-progress__wrapper">
          <Box className="progress-icon__wrapper">
            <img src={ProgressStar} className='progress-icon' />
          </Box>
          <Box className="progress-bar__wrapper">
            <Typography className="progress-bar__title">{configJSON.Dashboard.MyProgress}</Typography>
            <Box className="progress-bar">
              <Typography className="progress-bar__text">{progress.mpower}</Typography>
              <LinearProgress variant="determinate" value={Number(progress.mpower.split("%")[0])} data-test-id="progress-bar" />
            </Box>
          </Box>
        </Box>
      </Paper>
    )
  }

  renderCourseNavigation(props: CourseNavigationProps) {
    const { title, nextDisabled, prevDisabled } = props;
    return (
      <CourseNavigation className="course-navigation" data-test-id='course-navigation'>
        <Box className="title-wrapper">
          <Typography variant="h2" className="title">{title}</Typography>
        </Box>
        <Box className="navigation-buttons">
          <Button className="navigation-button" data-test-id="previous-button" disabled={prevDisabled} name='previous' onClick={() => this.handleArrowNavigation("previous")}>
            <ArrowBackIosRounded className="button-icon" />
          </Button>
          <Button className="navigation-button" data-test-id="next-button" disabled={nextDisabled} name="next" onClick={() => this.handleArrowNavigation("next")}>
            <ArrowForwardIosRounded className="button-icon" />
          </Button>
        </Box>
      </CourseNavigation>
    )
  }

  renderCourseCard(props: CourseProps) {
    const { courseDescription, courseImage, courseTitle, handleClick } = props;
    return (
      <CourseCard className="course-card" data-test-id='course-card' onClick={handleClick}>
        <Box className="course-image__wrapper" data-test-id="course-image-wrapper">
          <img className="course-image" data-test-id="course-image" src={courseImage} alt={courseTitle} />
        </Box>
        <Box className="course-details">
          <Box className="course-title__wrapper" data-test-id="course-title-wrapper">
            <Typography variant="body1" data-test-id="course-title" className="course-title">{courseTitle}</Typography>
          </Box>
          {courseDescription && (
            <Box className="course-description__wrapper">
              <Typography variant="body1" className="course-description" data-test-id="course-description">
                {courseDescription.length < 150 ? courseDescription : <ReadMore sliceLength={150}>{courseDescription}</ReadMore>}
              </Typography>
            </Box>
          )}
        </Box>
      </CourseCard>
    )
  }

  renderInfoBar(props: InfoBarProps) {
    const { icon, title, city, value } = props;
    return (
      <InfoBar className="info-bar" data-test-id="info-bar">
        {icon && <img className="info-bar-icon" data-test-id="info-bar-icon" src={icon} alt={title} />}
        <Box className="info-bar-content">
          <Box className="info-bar__wrapper">
            <Typography className="info-bar-title" data-test-id="info-bar-title">{title}</Typography>
          </Box>
          {city && (<Typography className="info-bar-city" data-test-id="info-bar-city">{city}</Typography>)}
          <Typography className="info-bar-value" data-test-id="info-bar-value">{value}</Typography>
        </Box>
      </InfoBar>
    )
  }

  renderAssignedCourses() {
    const { ongoingProgramIndex, mindMastery } = this.state;
    let prevDisabled = ongoingProgramIndex === 0 || mindMastery.length === 0;
    let nextDisabled = mindMastery.length > 1 ? ongoingProgramIndex === mindMastery.length - 1 || mindMastery.length === 0 : true;
    const selectedCourse = mindMastery[ongoingProgramIndex];
    const courseTitle = selectedCourse?.attributes.title;
    const courseImage = selectedCourse?.attributes.thumbnail;
    const courseDescription = selectedCourse?.attributes.description;
    const courseNavigationProps: CourseNavigationProps = { title: configJSON.Dashboard.AssignedCourse, nextDisabled, prevDisabled };
    const courseProps: CourseProps = { courseTitle, courseImage, courseDescription, handleClick: (() => this.goTo(configJSON.MindMasteryTexts.CourseDetailsPage, { courseId: mindMastery[ongoingProgramIndex].id })) };
    return (
      <Paper className="assigned-course" data-test-id="assigned-courses">
        {this.renderCourseNavigation(courseNavigationProps)}
        {mindMastery.length
          ? this.renderCourseCard(courseProps)
          : (<Typography data-test-id="assigned-courses-empty">{configJSON.MindMasteryTexts.NoMindMasteryCourseFound}</Typography>)
        }
      </Paper>
    )
  }

  renderSuperpowers() {
    const { superPowers } = this.state;
    const hapSparkCount = superPowers[0].attributes.count;
    const hapBeamCount = superPowers[1].attributes.count;
    const hapStarCount = superPowers[2].attributes.count;
    return (
      <Paper className="superpowers" data-test-id="superpowers">
        <Box className="superpower__title">
          <Typography className="superpower__text">{configJSON.Dashboard.SuperpowersCollected}</Typography>
        </Box>
        <Grid container className="superpower__container" justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={3} md={3} className="superpower__wrapper">
            <Box className="superpower-image__wrapper">
              <img className="superpower-image" src={HapSpark} alt="Cute doll with wand in hand" />
            </Box>
            <Box className="superpower-text__wrapper Hap-spark">
              <Typography className="superpower-name">{configJSON.Dashboard.Superpower1}</Typography>
              <Typography className="superpower-count" data-test-id="hapspark-count">{hapSparkCount}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} md={3} className="superpower__wrapper">
            <Box className="superpower-image__wrapper">
              <img className="superpower-image" src={HapBeam} alt="Cute doll with wand in hand" />
            </Box>
            <Box className="superpower-text__wrapper Hap-beam">
              <Typography className="superpower-name">{configJSON.Dashboard.Superpower2}</Typography>
              <Typography className="superpower-count" data-test-id="hapbeam-count">{hapBeamCount}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} md={3} className="superpower__wrapper">
            <Box className="superpower-image__wrapper">
              <img className="superpower-image" src={HapStar} alt="Cute doll with wand in hand" />
            </Box>
            <Box className="superpower-text__wrapper Hap-star">
              <Typography className="superpower-name">{configJSON.Dashboard.Superpower3}</Typography>
              <Typography className="superpower-count" data-test-id="hapstar-count">{hapStarCount}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  renderRewards() {
    return (
      <Paper className="rewards">
        <Box className="reward_image-container">
          <img className="reward-image" src={RewardsCollected} alt="reward-collected" />
        </Box>
      </Paper>
    )
  }

  renderReports() {
    const tableHeads = ["Course Name", "Action"];
    const reports = this.state.reports;
    return (
      <Paper className="reports" data-test-id="reports">
        <Typography className="reports__title">{configJSON.Dashboard.Report}</Typography>
        <TableContainer className="reports-table__container">
          <Table stickyHeader aria-label="sticky table" className="reports-table">
            <TableHead className="reports-table__headers">
              <TableRow>
                {tableHeads.map((tableHead: string, index: number) => (
                  <TableCell key={index} className="reports-table__head" align={index > 0 ? 'center' : 'left'}>
                    {tableHead}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.length ? reports.map((report: StudentReport) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={report.id} data-test-id="reports-row">
                    <TableCell align={'left'} className="reports-table__row">
                      <Typography>{report.attributes.mind_mastery_course.name}</Typography>
                    </TableCell>
                    <TableCell key={report.id} align={'center'} className="reports-table__row">
                      <a href={report.attributes.pdf_url} target="_blank">
                        <img className="report-download-image" src={documentIcon} />
                      </a>
                    </TableCell>
                  </TableRow>
                );
              }) : (
                <TableRow role="checkbox" tabIndex={-1} key={'no-report'} data-test-id="reports-row">
                  <TableCell align={'left'} className="reports-table__row">
                    <Typography>{configJSON.Dashboard.NoReportsFound}</Typography>
                  </TableCell>
                  <TableCell align={'left'} className="reports-table__row"></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  }

  renderStudentDashboard() {
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="stretch" className="details-progress__wrapper">
              <StudentDetails item xs={12} sm={6} md={6} lg={4} xl={4} className="user-details__wrapper">
                {this.renderStudentDetails()}
              </StudentDetails>
              <ProgressContainer item xs={12} sm={6} md={6} lg={8} xl={8} className="user-progress__item">
                {this.renderProgressBar()}
              </ProgressContainer>
            </Grid>
          </Grid>
          <AssignedCourses item xs={12} className="assigned-course__wrapper">
            {this.renderAssignedCourses()}
          </AssignedCourses>
          <Grid item xs={12}>
            <SuperpowersContainer container spacing={2} className="superpowers-rewards__wrapper">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {this.renderSuperpowers()}
              </Grid>
            </SuperpowersContainer>
          </Grid>
          <StudentReports item xs={12} className="reports__wrapper">
            {this.renderReports()}
          </StudentReports>
        </Grid>
      </Box>
    )
  }

  renderUserDetails() {
    const { userDetails, schoolOngoingProgramList } = this.state;
    const userFullName = `${userDetails.first_name} ${userDetails.last_name}`;
    const userProfilePicture = userDetails.image_url || profile;
    const userBio = userDetails.bio;
    const role = this.getUserRole();
    const userRole = role === 'parents' ? configJSON.Dashboard.Parent : userDetails.role;
    return (
      <Paper className="user-details" data-test-id="user-details">
        <Grid container className="user-details__wrapper" spacing={4}>
          <Grid container item xs={12} sm={3} md={3} lg={3} className="user-profile__container">
            <Grid item className="user-profile-picture__item">
              <Box className="user-profile-picture">
                <img alt={userFullName} src={userProfilePicture} />
              </Box>
            </Grid>
            <Grid item className="user-role">
              <Typography className="user-role__text">{userRole}</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={9} md={9} lg={9} className="user-name">
            <Typography className="user-name__text" data-test-id="user-full-name">{`Hello, ${userFullName}`}</Typography>
            {userBio && <Typography className="user-name__school">{userBio}</Typography>}
            {role === 'school' && (<Typography className="user-name__school">{userDetails.school_name}</Typography>)}
          </Grid>
        </Grid>
        {role === 'school' && (
          <Grid container className="program-count__container">
            <Paper className="program-count">
              <Typography className="program-count__heading">{configJSON.Dashboard.NumberOfPrograms}</Typography>
              <Typography className="program-count__value">{schoolOngoingProgramList.count}</Typography>
            </Paper>
          </Grid>
        )}
      </Paper>
    )
  }

  renderOngoingPrograms() {
    const { ongoingProgramIndex, ongoingPrograms } = this.state;
    let prevDisabled = ongoingProgramIndex === 0 || ongoingPrograms.length === 0;
    let nextDisabled = ongoingPrograms.length > 1 ? ongoingProgramIndex === ongoingPrograms.length - 1 || ongoingPrograms.length === 0 : true;
    const selectedCourse = ongoingPrograms[ongoingProgramIndex];
    const courseTitle = selectedCourse?.attributes.course_name;
    const courseImage = selectedCourse?.attributes.thumbnail_url;
    const courseDescription = selectedCourse?.attributes.description;
    const courseNavigationProps: CourseNavigationProps = { title: configJSON.Dashboard.OngoingPrograms, nextDisabled, prevDisabled };
    const courseProps: CourseProps = { courseTitle, courseImage, courseDescription, handleClick: (() => this.goTo(configJSON.Dashboard.TrainerMaterial)) };
    const infobarProps: InfoBarProps = { title: selectedCourse?.attributes.school_name, city: selectedCourse?.attributes.city, value: `Section : ${selectedCourse?.attributes.grade}` };

    return (
      <Paper className="ongoing-programs" data-test-id="ongoing-programs">
        {this.renderCourseNavigation(courseNavigationProps)}
        {ongoingPrograms.length
          ? (
            <React.Fragment>
              {this.renderCourseCard(courseProps)}
              {this.renderInfoBar(infobarProps)}
            </React.Fragment>
          )
          : (<Typography data-test-id="ongoing-programs-empty">{configJSON.Dashboard.NoOngoingProgramsFound}</Typography>)
        }
      </Paper>
    )
  }

  renderYouMatterFilter() {
    const { trainerYouMatterFilter } = this.state;
    const courseNameList = this.createYouMatterMenuList("course_name");
    const schoolList = this.createYouMatterMenuList("school");
    const gradeList = this.createYouMatterMenuList("grade");
    return (
      <Box className="trainer-you-matters_filter">
        <Grid container className="filter-wrapper" spacing={2}>
          <Grid item xs={12} sm={12} md={3} lg={4}>
            <CommonSelect data-test-id="course-filter" name="course_name" label={configJSON.Dashboard.Course} placeholder={configJSON.TrainerMPowerYouMatter.SelectCourse} onClear={() => this.handleYouMatterFilterChange('', 'course_name')} handleChange={({ target }) => this.handleYouMatterFilterChange(target.value, target.name)} value={trainerYouMatterFilter.course_name} menuList={courseNameList} />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <CommonSelect data-test-id="school-filter" name="school" label={configJSON.Dashboard.School} placeholder={configJSON.TrainerMPowerYouMatter.SelectSchool} onClear={() => this.handleYouMatterFilterChange('', 'school')} handleChange={({ target }) => this.handleYouMatterFilterChange(target.value, target.name)} value={trainerYouMatterFilter.school} menuList={schoolList} />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <CommonSelect data-test-id="grade-filter" name="grade" label={configJSON.Dashboard.Grade} placeholder={configJSON.TrainerMPowerYouMatter.SelectGrade} onClear={() => this.handleYouMatterFilterChange('', 'grade')} handleChange={({ target }) => this.handleYouMatterFilterChange(target.value, target.name)} value={trainerYouMatterFilter.grade} menuList={gradeList} />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={2} className="you-matter-button_container">
            <Box className="you-matter-button__wrapper">
              <Button className="you-matter__button" data-test-id="clear-filter" onClick={this.handleClearYouMatterFilter}>
                {configJSON.Dashboard.ClearAll}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }

  renderYouMattersTable() {
    const sortedTrainerYouMatters = this.getSortedTrainerYouMatter();
    return (
      <Paper className="table-container" data-test-id="trainer-you-matter">
        <Box className="table-title__wrapper">
          <Typography className="table-title">{configJSON.Dashboard.StudentsYouMatter}</Typography>
        </Box>
        {this.renderYouMatterFilter()}
        <Box className="table-wrapper">
          <Grid container item className="table-headers" data-test-id="you-matter-header">
            <Grid xs={3} item className="table-cell">{configJSON.Dashboard.YouMatterTitle}</Grid>
            <Grid xs={3} item className="table-cell">{configJSON.Dashboard.CourseName}</Grid>
            <Grid xs={2} item className="table-cell">{configJSON.Dashboard.DueDate}</Grid>
            <Grid xs={3} item className="table-cell">{configJSON.Dashboard.SchoolName}</Grid>
            <Grid xs={1} item className="table-cell">{configJSON.Dashboard.Grade}</Grid>
          </Grid>
          <Box className="table-body">
            {sortedTrainerYouMatters.length ? sortedTrainerYouMatters.map((trainerYouMatter: YouMatterProps) => {
              const youMatter = trainerYouMatter.attributes;
              return (
                <Grid key={trainerYouMatter.id} container className="table-row" data-test-id="you-matter-row">
                  <Grid xs={3} item className="table-cell">{youMatter.name}</Grid>
                  <Grid xs={3} item className="table-cell">{youMatter.course_name}</Grid>
                  <Grid xs={2} item className="table-cell">{youMatter.due_date}</Grid>
                  <Grid xs={3} item className="table-cell">{youMatter.school}</Grid>
                  <Grid xs={1} item className="table-cell">{youMatter.grade}</Grid>
                </Grid>
              )
            }) : (
              <Grid container className="table-row">
                <Grid xs={12} item className="you-matter-table__cell">No You Matters To Show</Grid>
              </Grid>
            )}
          </Box>
        </Box>
        <Box className="you-matter-button__wrapper">
          <Button className="you-matter__button" data-test-id="create-you-matter" onClick={this.handleCreateYouMatter}>
            {configJSON.Dashboard.CreateYouMatter}
          </Button>
        </Box>
      </Paper>
    )
  }

  renderTrainerDashboard() {
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="stretch" className="details-progress__wrapper">
              <UserDetails item xs={12} sm={12} md={12} lg={12} xl={12}>
                {this.renderUserDetails()}
              </UserDetails>
            </Grid>
          </Grid>
          <OngoingProgramsContainer item xs={12}>
            {this.renderOngoingPrograms()}
          </OngoingProgramsContainer>
          <CustomTable item xs={12}>
            {this.renderYouMattersTable()}
          </CustomTable>
        </Grid>
      </Box>
    )
  }

  renderChildrenList() {
    const { childrenDetails, activeChildIndex } = this.state;
    const children = childrenDetails.map((childrenOngoingProgram: ChildDetails) => ({ id: childrenOngoingProgram.id, name: childrenOngoingProgram.attributes.name }));
    const activeChild = childrenDetails.find((child: ChildDetails, idx: number) => idx === activeChildIndex);
    return (
      <ChildrensList className="children-list-wrapper" data-test-id="children-list">
        <Box className="children-list">
          {children.map((child: { id: string, name: string }, index: number) => {
            return (
              <Box key={child.id} className="child-button-wrapper" data-test-id="child-wrapper">
                <Button className={`children-button ${activeChildIndex === index && ("active")}`} name={child.name} onClick={() => this.handleChildChange(child.id)} data-test-id="child-button">
                  {child.name}
                </Button>
              </Box>
            )
          })}
        </Box>
        {activeChild && (
          <Box className="email-wrapper">
            <Typography variant="body2" className="email-text">Email ID :</Typography>
            <Typography variant="body2" className="email-value" data-test-id="child-email">{activeChild.attributes.email}</Typography>
          </Box>
        )}
      </ChildrensList>
    )
  }

  renderChildCourses(activeChild: ChildDetails) {
    const { ongoingProgramIndex } = this.state;
    const childCourses = activeChild.attributes.mind_mastery_courses;
    const activeChildCourse = childCourses[ongoingProgramIndex];
    const courseTitle = activeChildCourse?.attributes.title;
    const courseImage = activeChildCourse?.attributes.thumbnail;
    const courseDescription = activeChildCourse?.attributes.description;
    const courseProps: CourseProps = { courseTitle, courseImage, courseDescription, handleClick: (() => this.goTo(configJSON.Dashboard.ParentMaterial)) };
    const infoBar1Props: InfoBarProps = { title: activeChild.attributes.school_name, city: activeChild.attributes.school_city, value: `Section : ${activeChild.attributes.section_name}` }
    const infoBar2Props: InfoBarProps = { icon: mpowerIcon, title: configJSON.Dashboard.MPower, value: `${activeChild.attributes.mpower_checks_percentage}%` }
    return (
      <React.Fragment>
        {this.renderCourseCard(courseProps)}
        {this.renderInfoBar(infoBar1Props)}
        {this.renderInfoBar(infoBar2Props)}
      </React.Fragment>
    )
  }

  renderChildrensActivity() {
    const { ongoingProgramIndex, childrenDetails, activeChildIndex } = this.state;
    const currentChild = childrenDetails[activeChildIndex];
    const childCourses = currentChild?.attributes.mind_mastery_courses || [];
    let prevDisabled = ongoingProgramIndex === 0 || childCourses.length === 0;
    let nextDisabled = childCourses.length > 1 ? ongoingProgramIndex === childCourses.length - 1 || childCourses.length === 0 : true;
    const courseNavigationProps: CourseNavigationProps = { title: configJSON.Dashboard.ChildrensActivity, nextDisabled, prevDisabled };
    return (
      <Paper className="children-activity" data-test-id="children-activity">
        {this.renderCourseNavigation(courseNavigationProps)}
        {this.renderChildrenList()}
        {(currentChild && childCourses.length > 0)
          ? this.renderChildCourses(currentChild)
          : (<Typography data-test-id="children-activity-empty">{configJSON.Dashboard.NoChildrenActivity}</Typography>)
        }
      </Paper>
    )
  }

  renderParentDashboard() {
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="stretch" className="details-progress__wrapper">
              <UserDetails item xs={12} sm={12} md={12} lg={12} xl={12}>
                {this.renderUserDetails()}
              </UserDetails>
            </Grid>
          </Grid>
          <ChildrensActivity item xs={12}>
            {this.renderChildrensActivity()}
          </ChildrensActivity>
        </Grid>
      </Box>
    )
  }

  renderSchoolOngoingPrograms() {
    const { ongoingProgramIndex, schoolOngoingPrograms } = this.state;
    let prevDisabled = ongoingProgramIndex === 0 || schoolOngoingPrograms.length === 0;
    let nextDisabled = schoolOngoingPrograms.length > 1 ? ongoingProgramIndex === schoolOngoingPrograms.length - 1 || schoolOngoingPrograms.length === 0 : true;
    const ongoingProgram = schoolOngoingPrograms[ongoingProgramIndex];
    const courseNavigationProps: CourseNavigationProps = { title: configJSON.Dashboard.OngoingPrograms, nextDisabled, prevDisabled };
    const courseTitle = ongoingProgram?.attributes.course_name;
    const courseImage = ongoingProgram?.attributes.thumbnail_url;
    const courseDescription = ongoingProgram?.attributes.course_description;
    const courseProps: CourseProps = { courseTitle, courseImage, courseDescription, handleClick: (() => this.goTo(configJSON.Dashboard.ParentMaterial)) };
    const infobarProps: InfoBarProps = { title: ongoingProgram?.attributes.school_name, city: ongoingProgram?.attributes.city, value: `Section: ${ongoingProgram?.attributes.grade}` };
    return (
      <Paper className="ongoing-programs" data-test-id="school-ongoing-programs">
        {this.renderCourseNavigation(courseNavigationProps)}
        {schoolOngoingPrograms.length
          ? (
            <React.Fragment>
              {this.renderCourseCard(courseProps)}
              {this.renderInfoBar(infobarProps)}
            </React.Fragment>
          )
          : (<Typography data-test-id="ongoing-programs-empty">{configJSON.Dashboard.NoOngoingProgramsFound}</Typography>)
        }
      </Paper>
    )
  }

  renderSchoolOngoingProgramList() {
    const { schoolOngoingProgramList } = this.state;
    const programList = schoolOngoingProgramList.programs_list;

    return (
      <Paper className="table-container" data-test-id="school-ongoing-program-list">
        <Box className="table-title__wrapper">
          <Typography className="table-title">{configJSON.Dashboard.OngoingProgramsList}</Typography>
        </Box>
        <Box className="table-wrapper">
          <Grid container item className="table-headers" data-test-id="table-header">
            <Grid xs={3} item className="table-cell">{configJSON.Dashboard.CourseName}</Grid>
            <Grid xs={3} item className="table-cell">{configJSON.Dashboard.Grade}</Grid>
            <Grid xs={3} item className="table-cell">{configJSON.Dashboard.Date}</Grid>
            <Grid xs={3} item className="table-cell">{configJSON.Dashboard.Status}</Grid>
          </Grid>
          <Box className="table-body" data-test-id="table-body">
            {programList.length ? programList.map((programs: SchoolOngoingProgram) => {
              const program = programs.attributes;
              return (
                <Grid key={programs.id} container className="table-row" data-test-id="table-row">
                  <Grid xs={3} item className="table-cell">{program.course_name}</Grid>
                  <Grid xs={3} item className="table-cell">{program.grade}</Grid>
                  <Grid xs={3} item className="table-cell">{program.date}</Grid>
                  <Grid xs={3} item className="table-cell">{program.status}</Grid>
                </Grid>
              )
            }) : (
              <Grid container className="table-row" data-test-id="table-row-empty">
                <Grid xs={12} item className="you-matter-table__cell empty-text">{configJSON.Dashboard.NoScheduledUpcomingOrOngoingPrograms}</Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Paper>
    )
  }

  renderSchoolDashboard() {
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="stretch" className="details-progress__wrapper">
              <UserDetails item xs={12} sm={12} md={12} lg={12} xl={12}>
                {this.renderUserDetails()}
              </UserDetails>
            </Grid>
          </Grid>
          <OngoingProgramsContainer item xs={12}>
            {this.renderSchoolOngoingPrograms()}
          </OngoingProgramsContainer>
          <CustomTable item xs={12}>
            {this.renderSchoolOngoingProgramList()}
          </CustomTable>
        </Grid>
      </Box>
    )
  }

  renderEvent(event: UpcomingEvents) {
    const { day, date, eventName, courseName, eventTime, organizerPic, organizerName = "" } = this.handleEventData(event);
    return (
      <Paper key={event.id} className="upcoming-event" data-test-id="upcoming-event" onClick={() => this.togglePopup(event)}>
        <Box className="upcoming-event__date">
          <Typography className="day">{day}</Typography>
          <Typography className="date">{date}</Typography>
        </Box>
        <Box className="upcoming-event__details">
          <Typography className="event-name">{eventName}</Typography>
          <Typography className="event-course-name">{courseName}</Typography>
          <Typography className="event-time">{eventTime}</Typography>
          <Box className="event-profile">
            <img className="event-organizer__picture" src={organizerPic || profile} alt={organizerName} />
            <Typography className="event-organizer-name">{organizerName}</Typography>
          </Box>
        </Box>
      </Paper>
    )
  }

  renderUpcomingEvents() {
    const { upcomingEvents, currentDate } = this.state;
    const CustomPrevIcon = <img style={{ transform: "scaleX(-1)" }} src={calendarArrowRight} alt="Prev Icon" />
    const CustomNextIcon = <img src={calendarArrowRight} alt="Prev Icon" />
    return (
      <Paper className="upcoming-events" data-test-id="upcoming-events">
        <DateCalendar data-test-id="calendar-card" prevLabel={CustomPrevIcon} nextLabel={CustomNextIcon} formatShortWeekday={(locale, date) => this.formatDate(date.getDay())} value={currentDate} onChange={(newDate: Value) => this.handleDateChange(newDate)} />
        <Box className="event-list__container">
          <Box className="event-list__title-wrapper">
            <Typography className="event-list__title">{configJSON.Dashboard.UpcomingEvents}</Typography>
          </Box>
          <Box className="event-list__wrapper" data-test-id="event-list">
            {upcomingEvents.length
              ? upcomingEvents.map((upcomingEvent: UpcomingEvents) => this.renderEvent(upcomingEvent))
              : (<Box className="upcoming-event empty-state" data-test-id="upcoming-event"><Typography>{configJSON.Dashboard.NoUpcomingEvents}</Typography></Box>
              )}
          </Box>
        </Box>
      </Paper>
    )
  }

  renderProgramModal() {
    return (<ProgramModal isDashboard key={this.state.selectedEvent.id} selectedEvent={this.state.selectedEvent} open={this.state.showPopup} handleCancel={this.switchPopup} changeColor={() => { }} openDeleteModal={() => { }} openEditModal={() => { }} toggleGuestView={() => { }} />)
  }

  render(): React.ReactNode {
    const userRole = this.getUserRole();
    const { snackbar } = this.state;
    return (
      <SidebarLayout {...this.props} {...this.state}>
        <DashboardContainer data-test-id="dashboard-container">
          <Grid container spacing={4}>
            <Grid item xs={12} md={8} lg={8}>
              {userRole === 'student' && this.renderStudentDashboard()}
              {userRole === 'trainer' && this.renderTrainerDashboard()}
              {userRole === 'parents' && this.renderParentDashboard()}
              {userRole === 'school' && this.renderSchoolDashboard()}
            </Grid>
            <Grid container item xs={12} md={4} lg={4} className="calendar-rewards__wrapper">
              <Grid item xs={12}>
                <Box className="upcoming-events-and-rewards">
                  {this.renderUpcomingEvents()}
                  {userRole === 'student' && (<Rewards item xs={12}>{this.renderRewards()}</Rewards>)}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <CommonSnackBar data-test-id="snackbar" open={snackbar.enable} message={snackbar.message} type={snackbar.type} fontWeight={600} onClose={this.handleSnackbarClose} duration={4000} verticalPosition="top" horizontalPosition="center" />
        </DashboardContainer>
        {this.renderProgramModal()}
      </SidebarLayout>
    )
  }
  // Customizable Area End
}

export default Dashboard2;
// Customizable Area End

// Customizable Area Start
const StudentReports = styled(Grid)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  '& .reports': {
    padding: 24,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 22,
    boxShadow: '0px 2px 8px 0px #00000029',
    borderRadius: '8px',
    border: '1px solid #EFF3FF',
    '& .reports__title': {
      color: '#0056A3',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 600,
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '21px',
      },
    },
    '& .reports-table__container': {
      maxHeight: 450,
      '& .reports-table': {
        '& .reports-table__headers': {
          color: '#FFFFFF',
          '& .reports-table__head': {
            padding: '10px',
            background: '#0056A3',
            color: 'inherit'
          },
          '& th:first-child': {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
          },
          '& th:last-child': {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
          }
        },
        '& .reports-table__row': {
          padding: '10px',
          '& .report-download-image': {
            width: 20,
          }
        }
      },
    }
  }
}));

const Rewards = styled(Grid)(({ theme }: { theme: Theme }) => ({
  '& .rewards': {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 22,
    boxShadow: '0px 2px 8px 0px #00000029',
    borderRadius: '8px',
    border: '1px solid #EFF3FF',
    alignItems: 'center',
    justifyContent: 'center',
    '& .reward_image-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '100%',
      maxHeight: '100%',
      [theme.breakpoints.up('lg')]: {
        width: '100%',
      },
      '& .reward-image': {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
      }
    }
  }
}));

const SuperpowersContainer = styled(Grid)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flex: 1,
  '& .superpowers': {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 22,
    boxShadow: '0px 2px 8px 0px #00000029',
    borderRadius: '8px',
    border: '1px solid #EFF3FF',
    '& .superpower__title': {
      '& .superpower__text': {
        color: '#0056A3',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        [theme.breakpoints.up('md')]: {
          fontSize: '18px',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '21px',
        },
      },
    },
    '& .superpower__container': {
      rowGap: 20,
      '& .superpower__wrapper': {
        display: 'flex',
        gap: 25,
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.up('lg')]: {
          gap: 25,
        },
        '& .superpower-image__wrapper': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .superpower-image': {
            width: 180,
            height: 200,
            objectFit: 'contain',
          }
        },
        '& .superpower-text__wrapper': {
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          '&.Hap-spark': {
            color: '#EF4444'
          },
          '&.Hap-beam': {
            color: '#FBBF24'
          },
          '&.Hap-star': {
            color: '#0156A2'
          },
          '& .superpower-name': {
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '21px',
            textAlign: 'center',
            color: 'inherit',
          },
          '& .superpower-count': {
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '24px',
            textAlign: 'center',
            color: 'inherit',
            [theme.breakpoints.up('lg')]: {
              fontSize: '18px',
            },
          },
        }
      },
      '& .superpower__empty': {
        width: '100%',
      }
    }
  }
}));

const AssignedCourses = styled(Grid)({
  display: 'flex',
  '& .assigned-course': {
    padding: 24,
    width: '100%',
    boxShadow: '0px 2px 8px 0px #00000029',
    borderRadius: '8px',
    border: '1px solid #EFF3FF',
  }
});

const ProgressContainer = styled(Grid)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  '& .user-progress': {
    boxShadow: '0px 2px 8px 0px #00000029',
    borderRadius: '8px',
    border: '1px solid #EFF3FF',
    padding: '32px 42px',
    display: 'flex',
    flex: 1,
    columnGap: 20,
    [theme.breakpoints.up('lg')]: {
      padding: '32px 50px',
    },
    '& .user-progress__wrapper': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 20,
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        gap: 40,
      },
      '& .progress-icon__wrapper': {
        width: 100,
        height: 100,
        [theme.breakpoints.up('lg')]: {
          width: 120,
          height: 120,
        },
        '& .progress-icon': {
          width: '100%',
          height: '100%'
        }
      },
      '& .progress-bar__wrapper': {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10,
        justifyContent: 'center',
        width: '100%',
        [theme.breakpoints.up('lg')]: {
          width: 'auto',
        },
        '& .progress-bar__title': {
          fontSize: '16px',
          fontWeight: '600',
          lineHeight: '27px',
          color: '#0057A4',
          [theme.breakpoints.up('md')]: {
            fontSize: '18px',
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: '21px',
          },
        },
        '& .progress-bar': {
          display: 'flex',
          flexDirection: 'column',
          rowGap: 12,
          [theme.breakpoints.up('lg')]: {
            width: '90%',
          },
          '& .progress-bar__text': {
            fontSize: '14px',
            fontWeight: '400px',
            lineHeight: '22px',
            [theme.breakpoints.up('md')]: {
              fontSize: '16px',
            },
          }
        }
      }
    },

  }
}));

const StudentDetails = styled(Grid)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  '& .user-details': {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '32px 44px',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: '24px',
    boxShadow: '0px 2px 8px 0px #00000029',
    borderRadius: '8px',
    border: '1px solid #EFF3FF',
    [theme.breakpoints.up('lg')]: {
      padding: '24px',
    },
    '& .user-profile-picture__item': {
      display: 'flex',
      justifyContent: 'center',
      '& .user-profile-picture': {
        width: 100,
        height: 100,
        [theme.breakpoints.up('lg')]: {
          width: 100,
          height: 100,
        },
        '& img': {
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          objectFit: 'cover'
        }
      }
    },
    '& .user-name': {
      color: '#0057A4',
      '& .user-name__text': {
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '27px',
        [theme.breakpoints.up('md')]: {
          fontSize: '18px',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '20px',
        },

      }
    }
  }
}));

const UserDetails = styled(Grid)(({ theme }: { theme: Theme }) => ({
  '& .user-details': {
    padding: 24,
    boxShadow: '0px 2px 8px 0px #00000029',
    borderRadius: '8px',
    border: '1px solid #EFF3FF',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    },
    '& .user-details__wrapper': {
      display: 'flex',
      flexDirection: 'column',
      // gap: 10,
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      },
      '& .user-profile__container': {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        alignItems: 'center',
        '& .user-profile-picture__item': {
          display: 'flex',
          justifyContent: 'center',
          '& .user-profile-picture': {
            maxWidth: 100,
            maxHeight: 100,
            width: 100,
            height: 100,
            [theme.breakpoints.up('lg')]: {
              maxWidth: 100,
              maxHeight: 100,
            },
            '& img': {
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              objectFit: 'cover'
            }
          }
        },
        '& .user-role': {
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .user-role__text': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            textAlign: 'center',
            color: '#A8A29E'
          }
        },
      },
      '& .user-name': {
        color: '#0057A4',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        [theme.breakpoints.up('sm')]: {
          height: 120,
          alignItems: 'flex-start',
        },
        [theme.breakpoints.up('lg')]: {
          height: 150,
        },
        '& .user-name__text': {
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: '700',
          lineHeight: '32px',
          [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-start',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '18px',
            justifyContent: 'flex-start',
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: '24px',
            textAlign: 'left'
          },
        },
        '& .user-name__school': {
          color: '#3b3b3bcc',
          fontSize: '16px',
          fontWeight: 400,
        }
      }
    },
    '& .program-count__container': {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '200px',
      },
      '& .program-count': {
        boxShadow: '0px 2px 8px 0px #00000029',
        borderRadius: '8px',
        border: '1px solid #EFF3FF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 10,
        width: '100%',
        '& .program-count__heading': {
          color: '#0056A3',
          fontSize: '12px',
          textAlign: 'center',
          fontWeight: 500,
        },
        '& .program-count__value': {
          color: '#0056A3',
          fontSize: '68px',
          fontWeight: 600,
        }
      }
    }

  }
}));

const OngoingProgramsContainer = styled(Grid)({
  '& .ongoing-programs': {
    padding: 24,
    display: 'flex',
    gap: 10,
    flexDirection: 'column',
    boxShadow: '0px 2px 8px 0px #00000029',
    borderRadius: '8px',
    border: '1px solid #EFF3FF',
  }
});

const CustomTable = styled(Grid)(({ theme }: { theme: Theme }) => ({
  '& .table-container': {
    boxShadow: '0px 2px 8px 0px #00000029',
    borderRadius: '8px',
    border: '1px solid #EFF3FF',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    padding: 24,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .table-title__wrapper': {
      '& .table-title': {
        color: '#0056A3',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        [theme.breakpoints.up('sm')]: {
          fontSize: '18px',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '24px',
        }
      },
    },
    '& .trainer-you-matters_filter': {
      '& .filter-wrapper': {
        '& .select-label': {
          fontSize: '12px',
          [theme.breakpoints.up('md')]: {
            fontSize: '14px',
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: '16px',
          },
          [theme.breakpoints.up('xl')]: {
            fontSize: '18px',
          }
        },
        '& .select-dropdown': {
          height: '40px !important',
          '& .MuiSelect-icon': {
            top: '17px !important',
            right: '15px !important'
          },
          '& .select-label': {
            fontSize: '12px',
            [theme.breakpoints.up('md')]: {
              fontSize: '14px',
            },
            [theme.breakpoints.up('lg')]: {
              fontSize: '16px',
            },
            [theme.breakpoints.up('xl')]: {
              fontSize: '18px',
            }
          },
        }
      }
    },
    '& .table-wrapper': {
      marginBottom: '20px',
      maxHeight: '340px',
      maxWidth: 'calc(100vw - 130px)',
      minWidth: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 'calc(100vw - 162px)',
        maxHeight: '350px',
      },
      [theme.breakpoints.up('md')]: {
        maxHeight: '380px',
      },
      [theme.breakpoints.up('lg')]: {
        maxHeight: '400px',
      },
      [theme.breakpoints.up('xl')]: {
        maxHeight: '340px',
      },
      overflowX: 'auto',
      '& .table-headers': {
        background: '#0057A4',
        borderRadius: '8px',
        justifyContent: 'space-between',
        padding: '15px 24px',
        marginBottom: '16px',
        minWidth: '550px',
        position: 'sticky',
        top: 0,
        left: 0,
        [theme.breakpoints.up('md')]: {
          minWidth: '100%',
        },
        '& .table-cell': {
          paddingLeft: 0,
          paddingRight: 10,
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '18px',
          color: '#FFFFFF',
          [theme.breakpoints.up('md')]: {
            fontSize: '12px',
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: '14px',
          },
          [theme.breakpoints.up('xl')]: {
            fontSize: '16px',
          }
        }
      },
      '& .table-body': {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        paddingBlockEnd: 20,
      },
      '& .table-row': {
        boxShadow: '0px 2px 8px 0px #00000029',
        borderRadius: '8px',
        border: '1px solid #EFF3FF',
        justifyContent: 'space-between',
        padding: '15px 24px',
        cursor: 'pointer',
        minWidth: '550px',
        [theme.breakpoints.up('md')]: {
          minWidth: '100%',
        },
        '& .table-cell': {
          paddingLeft: 0,
          paddingRight: 10,
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '18px',
          color: '#000000',
          [theme.breakpoints.up('md')]: {
            fontSize: '12px',
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: '14px',
          },
          [theme.breakpoints.up('xl')]: {
            fontSize: '16px',
          },
          '&.empty-text': {
            fontSize: '14px',
            [theme.breakpoints.up('md')]: {
              fontSize: '12px',
            },
            [theme.breakpoints.up('lg')]: {
              fontSize: '14px',
            },
            [theme.breakpoints.up('xl')]: {
              fontSize: '16px',
            },
          }
        }
      }
    },
    '& .you-matter-button_container': {
      display: 'flex',
      alignItems: 'self-end',
      justifyContent: 'flex-end'
    },
    '& .you-matter-button__wrapper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '100%',
      '& .you-matter__button': {
        padding: '10px 16px',
        borderRadius: '8px',
        maxHeight: '40px',
        background: '#0057A4',
        color: '#FFFFFF',
        fontWeight: 600,
        lineHeight: '21px',
        fontSize: '12px',
        [theme.breakpoints.up('md')]: {
          fontSize: '14px',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '16px',
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: '18px',
        },
        '& .MuiButton-label': {
          textTransform: 'capitalize'
        }
      }
    }
  }
}));

const ChildrensList = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  gap: 20,
  '& .children-list': {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: 15,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },
  '& .child-button-wrapper': {
    '& .children-button': {
      width: '100%',
      padding: '10px 16px',
      borderRadius: '4px',
      maxHeight: '32px',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '21px',
      background: '#FFFFFF',
      color: '#0057A4',
      border: '1px solid #0056A3',
      textTransform: 'capitalize',
      [theme.breakpoints.up('md')]: {
        maxHeight: '44px',
        fontSize: '14px',
      },
      '&.active': {
        background: '#0057A4',
        color: '#FFFFFF',
        border: 'none',
      }
    }
  }
}));

const ChildrensActivity = styled(Grid)(({ theme }: { theme: Theme }) => ({
  '& .children-activity': {
    padding: 24,
    display: 'flex',
    gap: 20,
    flexDirection: 'column',
    boxShadow: '0px 2px 8px 0px #00000029',
    borderRadius: '8px',
    border: '1px solid #EFF3FF',
  },
  '& .email-wrapper': {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      gap: 10,
      flexDirection: 'row',
    },
    '& .email-text': {
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '24px',
      color: '#0057A4',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
      },
    },
    '& .email-value': {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '22px',
      color: '#0F172A',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
      },
    }
  }
}));

const CourseNavigation = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 16,
  gap: 10,
  '& .title-wrapper': {
    display: 'flex',
    alignItems: 'center',
    '& .title': {
      color: '#0056A3',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 600,
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '21px',
      },
    },
  },
  '& .navigation-buttons': {
    display: 'flex',
    gap: 10,
    '& .navigation-button': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 25,
      height: 25,
      borderRadius: '8px',
      backgroundColor: '#0056A3',
      minWidth: 'auto',
      [theme.breakpoints.up('md')]: {
        width: 30,
        height: 30,
      },
      '& .MuiButton-label': {
        color: '#FFFFFF',
      },
      '&.MuiButton-root.Mui-disabled': {
        backgroundColor: 'rgba(0, 86, 163, 0.5)'
      },
      '& .button-icon': {
        width: 15,
        height: 15,
        [theme.breakpoints.up('md')]: {
          width: 20,
          height: 20,
        },
      }
    }
  }
}));

const DashboardContainer = styled(Paper)({
  padding: 24,
  borderRadius: 12,
  boxShadow: '2px 10px 16px rgba(0, 0, 0, 0.0908271)',
  '& .calendar-rewards__wrapper': {
    gap: 20,
    '& .upcoming-events-and-rewards': {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      '& .upcoming-events': {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        borderRadius: 12,
        '& .event-list__container': {
          paddingInline: 16,
          paddingBottom: 16,
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          '& .event-list__title-wrapper': {
            paddingBottom: 10,
            '& .event-list__title': {
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '24px',
              color: '#0057A4',
            },
            borderBottom: '1px solid #0056A3',
          },
          '& .event-list__wrapper': {
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            maxHeight: '375px',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }
        },
        '& .upcoming-event': {
          cursor: 'pointer',
          display: 'flex',
          gap: 10,
          padding: 10,
          borderRadius: 10,
          border: '0.2px solid #e3e3e3',
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.122705)',
          '&.empty-state': {
            padding: 0,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
            letterSpacing: '-0.01em',
            textAlign: 'left',
            border: 'none',
            boxShadow: 'none'
          },
          '& .upcoming-event__date': {
            minWidth: 40,
            maxWidth: 40,
            padding: 10,
            color: '#FFFFFF',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'linear-gradient(131deg, #B3DBFF -33.84%, #4B90CD 19.41%, #0156A2 95.54%)',
            '& .day': {
              fontSize: '18px',
              fontStyle: 'normal',
              textAlign: 'center',
              fontWeight: 700
            },
            '& .date': {
              fontSize: '24px',
              fontStyle: 'normal',
              textAlign: 'center',
              fontWeight: 700
            }
          },
          '& .upcoming-event__details': {
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            '& .event-name': {
              color: '#0056A3',
              cursor: 'pointer',
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '24px',
            },
            '& .event-course-name': {
              color: '#0056A3',
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '18px'
            },
            '& .event-time': {
              color: '#717171',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '21px'
            },
            '& .event-profile': {
              display: 'flex',
              gap: 10,
              alignItems: 'center',
              '& .event-organizer__picture': {
                width: 20,
                height: 20,
                border: '0.5px solid #297FCA',
                borderRadius: '100%',
              },
              '& .event-organizer-name': {
                color: '#0056A3',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '18px',
              },
            },
          }
        }
      },
    }
  }
});
// Customizable Area End