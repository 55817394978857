import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Value } from "react-calendar/dist/cjs/shared/types";
import moment from "moment";
const navigation = require("react-navigation");
// Customizable Area End

export const configJSON = require("./config.js");

// Customizable Area Start
export interface ApiCallInterface {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object | string;
}

export interface UserDetails {
  id: number;
  first_name: string;
  last_name: string;
  birthday: string | null;
  mobile_number: string;
  gender: string;
  email: string;
  parents_email: string;
  status: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  pincode: string | number | null;
  registered: boolean;
  bio: string | null;
  image_url: string | null;
  role: string;
  aadhar_card_number: string | null;
  aadhar_photo_url: string | null;
  aadhar_card_file_name: string | null;
  school_name: string | null;
  school_class: string | null;
  childrens?: Child[]
}

export interface UpcomingEvents {
  id: string,
  course_id: number,
  course_name: string | null,
  type: string,
  attributes: {
    name: string,
    time: string,
    date: string,
    course_name?: string,
    end_date?: string;
    submission_status?: string;
    organizer: {
      name: string,
      profile_photo_url: string | null
    }
  }
}

export interface Guest {
  id: string,
  type: string,
  attributes: {
    email: string,
    profile_photo_url: string | null
  }
}
const colors = ["yellow", "red", "blue", "purple", "indigo"] as const;
type ColorType = typeof colors[number];

export interface Guest {
  id: string,
  type: string,
  attributes: {
    email: string,
    profile_photo_url: string | null,
  }
}
export interface ProgramScheduleEvent {
  id: string,
  type: string,
  attributes: {
    name: string,
    day_date_time: string,
    location: string,
    date: string;
    time: string;
    course_title: string;
    organizer: {
      name: string,
      profile_photo_url: string | null,
    },
    program_guests: Guest[],
    notes: string,
    total_guests: number,
    colour: ColorType,
    document_url: string | null,
    school_name?: string[],
  }
}

export interface UserProgress {
  mpower: string;
}

export interface CourseNavigationProps {
  title: string,
  nextDisabled: boolean;
  prevDisabled: boolean;
}
export interface CourseProps {
  courseTitle: string;
  courseImage: string,
  courseDescription: string | null,
  handleClick?: () => void;
}

export interface InfoBarProps {
  icon?: string;
  title: string;
  city?: string;
  value: string;
}
export interface MindMastery {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: string;
    thumbnail: string;
  };
}

export interface Superpower {
  id: string | number;
  type: string;
  attributes: {
    title: string,
    name: string,
    count: number,
  }
}

export interface StudentReport {
  id: string;
  type: string;
  attributes: {
    id: string;
    status: string;
    pdf_url?: string;
    mind_mastery_course: {
      id: number;
      name: string;
    };
    student: {
      id: number;
      name: string;
    };
  };
}

export interface CourseStatus {
  id: string;
  type: string;
  attributes: {
    id: number,
    student_id: number,
    mind_mastery_course_id: number,
    status: string;
  }
}

interface ProgramGuestProps {
  id: string;
  type: string;
  attributes: {
    email: string;
    profile_photo_url: string | null | undefined
  }
}

interface EventDetailsAttributes {
  name: string;
  day_date_time: string;
  location: string;
  organizer: {
    name: string;
    profile_photo_url: string | null | undefined
  },
  program_guests: ProgramGuestProps[]
}

export interface EventDetails {
  id: string;
  type: string;
  attributes: EventDetailsAttributes
}

export interface OnGoingProgramProps {
  id: string,
  type: string,
  attributes: {
    course_name: string,
    description: string,
    school_name: string,
    grade: string,
    city: string,
    program_progress: number,
    status: string,
    date: string,
    thumbnail_url: string,
  }
}

export interface YouMatterProps {
  id: string,
  type: string,
  attributes: {
    id: number,
    start_date: string,
    due_date: string,
    name: string,
    course_name: string,
    school: string,
    grade: string,
    status: string,
  }
}

export interface TrainerYouMatterFilter {
  course_name: string;
  school: string;
  grade: string;
}

export interface OngoingMindMasterCourse {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: string;
    thumbnail: string;
  }
}

export interface ChildDetails {
  id: string;
  type: string;
  attributes: {
    email: string;
    name: string;
    school_name: string;
    school_city: string;
    section_name: string;
    mpower_checks_percentage: number,
    mind_mastery_courses: OngoingMindMasterCourse[]
  }
}

export interface Child {
  id: string;
  type: string;
  attributes: {
    email: string;
    school_name: string | null;
    school_class: string | null;
    name: string;
  }
}

export interface SchoolOngoingProgram {
  id: string;
  type: string;
  attributes: {
    course_name: string;
    course_description: string;
    school_name: string;
    grade: string;
    city: string;
    program_progress: number,
    status: string;
    date: string;
    thumbnail_url: string;
  }
}

export interface SchoolOngoingProgramList {
  programs_list: SchoolOngoingProgram[];
  count: number;
}
// Customizable Area End

export interface Props {
  navigation: typeof navigation;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  userDetails: UserDetails;
  userRole: string;
  progress: UserProgress;
  ongoingProgramIndex: number;
  mindMastery: MindMastery[],
  currentDate: Date;
  events: ProgramScheduleEvent[],
  upcomingEvents: UpcomingEvents[],
  reports: StudentReport[];
  superPowers: Superpower[];
  showPopup: boolean;
  selectedEvent: ProgramScheduleEvent;

  ongoingPrograms: OnGoingProgramProps[];
  trainerYouMatters: YouMatterProps[],
  sortedTrainerYouMatters: YouMatterProps[],
  trainerYouMatterFilter: TrainerYouMatterFilter,

  childrenDetails: ChildDetails[],
  activeChildIndex: number;
  schoolOngoingPrograms: SchoolOngoingProgram[];
  schoolOngoingProgramList: SchoolOngoingProgramList;
  snackbar: {
    enable: boolean;
    message: string;
    type: "success" | "error" | "warning" | "info";
  }
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class Dashboard2Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiUserDetailsId: string = "";
  apiUpcomingEventsId: string = "";
  apiProgramScheduleId: string = "";
  apiUserProgressId: string = "";
  apiMindMasteryId: string = "";
  apiUserSuperPowers: string = "";
  apiStudentReportsId: string = "";
  apiEventDetailsId: string = "";
  apiCourseStatusId: string = "";

  apiOngoingProgramId: string = "";
  apiTrainerYouMatterId: string = "";

  apiChildrenDetailsId: string = "";

  apiSchoolOngoingProgramsId: string = "";
  apiSchoolOngoingProgramListId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      userDetails: {
        id: 0,
        first_name: "",
        last_name: "",
        birthday: "",
        mobile_number: "",
        gender: "",
        email: "",
        parents_email: "",
        status: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        pincode: null,
        registered: true,
        bio: "",
        image_url: "",
        role: "",
        aadhar_card_number: null,
        aadhar_photo_url: null,
        aadhar_card_file_name: null,
        school_name: "",
        school_class: "",
        childrens: [],
      },
      userRole: '',
      progress: { mpower: '0%' },
      currentDate: new Date(),
      mindMastery: [],
      superPowers: [{ "id": 1, "type": "super_powers", "attributes": { "title": "Hap Spark", "name": "hap_spark", "count": 0 } }, { "id": 2, "type": "super_powers", "attributes": { "title": "Hap Beam", "name": "hap_beam", "count": 0 } }, { "id": 3, "type": "super_powers", "attributes": { "title": "Hap Star", "name": "hap_star", "count": 0 } }],
      ongoingProgramIndex: 0,
      reports: [],
      upcomingEvents: [],
      events: [],
      showPopup: false,
      selectedEvent: {} as ProgramScheduleEvent,

      ongoingPrograms: [],
      trainerYouMatters: [],
      sortedTrainerYouMatters: [],
      trainerYouMatterFilter: {
        course_name: "",
        school: "",
        grade: "",
      },

      childrenDetails: [],
      activeChildIndex: 0,
      schoolOngoingPrograms: [],
      schoolOngoingProgramList: { programs_list: [], count: 0 },
      snackbar: { enable: false, message: "", type: 'info' },
    };

    this.getUserDetails = this.getUserDetails.bind(this);
    this.getUserRole = this.getUserRole.bind(this);
    this.getUpcomingEvents = this.getUpcomingEvents.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.getEvents = this.getEvents.bind(this);
    this.getUserProgress = this.getUserProgress.bind(this);
    this.getMindMastery = this.getMindMastery.bind(this);
    this.getUserSuperPowers = this.getUserSuperPowers.bind(this);
    this.getStudentReports = this.getStudentReports.bind(this);
    this.getEventDetails = this.getEventDetails.bind(this);
    this.getCourseStatus = this.getCourseStatus.bind(this);
    this.getTrainerOngoingPrograms = this.getTrainerOngoingPrograms.bind(this);
    this.getTrainerYouMatters = this.getTrainerYouMatters.bind(this);
    this.getChildrenDetails = this.getChildrenDetails.bind(this);
    this.getSchoolOngoingPrograms = this.getSchoolOngoingPrograms.bind(this);
    this.getSchoolOngoingProgramList = this.getSchoolOngoingProgramList.bind(this);
    this.handleApiResponses = this.handleApiResponses.bind(this);
    this.handleUserDetails = this.handleUserDetails.bind(this);
    this.handleUpcomingEvents = this.handleUpcomingEvents.bind(this);
    this.handleProgramScheduleEvents = this.handleProgramScheduleEvents.bind(this);
    this.handleUserProgress = this.handleUserProgress.bind(this);
    this.handleMindMastery = this.handleMindMastery.bind(this);
    this.handleSuperpowers = this.handleSuperpowers.bind(this);
    this.handleStudentReports = this.handleStudentReports.bind(this);
    this.handleCourseStatus = this.handleCourseStatus.bind(this);
    this.handleOngoingPrograms = this.handleOngoingPrograms.bind(this);
    this.handleTrainerYouMatter = this.handleTrainerYouMatter.bind(this);
    this.handleChildsOngoingPrograms = this.handleChildsOngoingPrograms.bind(this);
    this.handleSchoolOngoingPrograms = this.handleSchoolOngoingPrograms.bind(this);
    this.handleSchoolOngoingProgramList = this.handleSchoolOngoingProgramList.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleArrowNavigation = this.handleArrowNavigation.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSingleEvent = this.handleSingleEvent.bind(this);
    this.handleEventDetails = this.handleEventDetails.bind(this);
    this.handleEventValidation = this.handleEventValidation.bind(this);
    this.handleSpecificEventType = this.handleSpecificEventType.bind(this);
    this.handleCreateYouMatter = this.handleCreateYouMatter.bind(this);
    this.handleYouMatterFilterChange = this.handleYouMatterFilterChange.bind(this);
    this.handleClearYouMatterFilter = this.handleClearYouMatterFilter.bind(this);
    this.handleChildChange = this.handleChildChange.bind(this)
    this.getSortedTrainerYouMatter = this.getSortedTrainerYouMatter.bind(this);
    this.switchPopup = this.switchPopup.bind(this);
    this.goTo = this.goTo.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.error) {
        this.handleApiResponses(apiRequestCallId, responseJson);
      } else {
        this.handleError(responseJson);
      }
    }
    // Customizable Area End
  }

  handleApiResponses(apiRequestCallId: string, responseJson: any) {
    const handlers: { [key: string]: (response: any) => void } = {
      [this.apiUserDetailsId]: (response) => this.handleUserDetails(response.user.data.attributes),
      [this.apiUpcomingEventsId]: (response) => this.handleUpcomingEvents(response),
      [this.apiProgramScheduleId]: (response) => this.handleProgramScheduleEvents(response),
      [this.apiUserProgressId]: (response) => this.handleUserProgress(response),
      [this.apiMindMasteryId]: (response) => this.handleMindMastery(response.data),
      [this.apiUserSuperPowers]: (response) => this.handleSuperpowers(response.data),
      [this.apiStudentReportsId]: (response) => this.handleStudentReports(response.reports),
      [this.apiEventDetailsId]: (response) => this.handleEventDetails(response.data),
      [this.apiCourseStatusId]: (response) => this.handleCourseStatus(response.data),
      [this.apiOngoingProgramId]: (response) => this.handleOngoingPrograms(response),
      [this.apiTrainerYouMatterId]: (response) => this.handleTrainerYouMatter(response.you_matter),
      [this.apiChildrenDetailsId]: (response) => this.handleChildsOngoingPrograms(response.data),
      [this.apiSchoolOngoingProgramsId]: (response) => this.handleSchoolOngoingPrograms(response.programs),
      [this.apiSchoolOngoingProgramListId]: (response) => this.handleSchoolOngoingProgramList(response),
    };

    const handler = handlers[apiRequestCallId];
    if (handler) {
      handler(responseJson);
    } else {
      console.log("Default called");
    }
  }

  // Customizable Area Start
  apiCall = async (data: ApiCallInterface) => {
    const { contentType, method, endPoint, body } = data;
    let token = localStorage.getItem("user_token");
    const header = { "Content-Type": contentType, token, "Authorization": token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async getUserDetails() {
    this.apiUserDetailsId = await this.apiCall({
      contentType: configJSON.dashboardContentType,
      method: configJSON.dashboardGetApiMethod,
      endPoint: configJSON.getUserDetailsApiEndPoint,
    });
  }

  async getUpcomingEvents(currentDate?: Date) {
    const userRole = this.getUserRole();
    let endpoint = configJSON.Dashboard.StudentUpcomingEventsEndPoint;

    let bodyParam: string = "";
    if (currentDate) {
      bodyParam = `?[date]=${moment(currentDate).format("DD-MM-YYYY")}`
    }

    switch (userRole) {
      case 'trainer':
        endpoint = configJSON.Dashboard.TrainerUpcomingEventsEndPoint;
        break;
      case "school":
        endpoint = configJSON.Dashboard.SchoolUpcomingEventsEndPoint;
        break;
      case "parents":
        endpoint = configJSON.Dashboard.ParentsUpcomingEventsEndPoint;
        break;
    }

    await this.getEvents();

    this.apiUpcomingEventsId = await this.apiCall({
      contentType: configJSON.dashboardContentType,
      method: configJSON.dashboardGetApiMethod,
      endPoint: (endpoint + bodyParam),
    });
  }

  async getEvents() {
    this.apiProgramScheduleId = await this.apiCall({
      contentType: configJSON.dashboardContentType,
      method: configJSON.programSchedulerGetApiMethod,
      endPoint: configJSON.Dashboard.getEventsEndPoint
    });
  }

  async getUserProgress() {
    this.apiUserProgressId = await this.apiCall({
      contentType: configJSON.dashboardContentType,
      method: configJSON.dashboardGetApiMethod,
      endPoint: configJSON.getUserProgressEndPoint
    });
  }

  async getMindMastery() {
    this.apiMindMasteryId = await this.apiCall({
      contentType: configJSON.dashboardContentType,
      method: configJSON.dashboardGetApiMethod,
      endPoint: configJSON.getMindMateryEndPoint
    });
  }

  async getUserSuperPowers() {
    this.apiUserSuperPowers = await this.apiCall({
      contentType: configJSON.dashboardContentType,
      method: configJSON.dashboardGetApiMethod,
      endPoint: configJSON.getSuperPowersEndPoint,
    });
  }

  async getStudentReports() {
    this.apiStudentReportsId = await this.apiCall({
      contentType: configJSON.dashboardContentType,
      method: configJSON.dashboardGetApiMethod,
      endPoint: configJSON.getReportEndPoint,
    });
  }

  async getEventDetails(id: string) {
    this.apiEventDetailsId = await this.apiCall({
      contentType: configJSON.dashboardContentType,
      method: configJSON.programSchedulerGetApiMethod,
      endPoint: `${configJSON.Dashboard.getEventsEndPoint}/${id}`
    });
  }

  async getCourseStatus(courseId: number | undefined) {
    const endpoint = `${configJSON.MindMasteryTexts.courseDetails}?[mind_mastery_course_id]=${courseId}`;
    this.apiCourseStatusId = await this.apiCall({
      contentType: configJSON.dashboardContentType,
      method: configJSON.programSchedulerGetApiMethod,
      endPoint: endpoint
    });
  }

  async getTrainerOngoingPrograms() {
    this.apiOngoingProgramId = await this.apiCall({
      contentType: configJSON.dashboardContentType,
      method: configJSON.dashboardGetApiMethod,
      endPoint: configJSON.Dashboard.TrainerOngoingProgramsEndPoint,
    });
  }

  async getTrainerYouMatters() {
    this.apiTrainerYouMatterId = await this.apiCall({
      contentType: configJSON.dashboardContentType,
      method: configJSON.dashboardGetApiMethod,
      endPoint: configJSON.Dashboard.TrainerStudentYouMatterEndPoint,
    });
  }

  async getChildrenDetails() {
    this.apiChildrenDetailsId = await this.apiCall({
      contentType: configJSON.dashboardContentType,
      method: configJSON.dashboardGetApiMethod,
      endPoint: configJSON.getParentsChildrenDetails,
    })
  }

  async getSchoolOngoingPrograms() {
    this.apiSchoolOngoingProgramsId = await this.apiCall({
      contentType: configJSON.dashboardContentType,
      method: configJSON.dashboardGetApiMethod,
      endPoint: configJSON.getOnGoingProgramsEndPoint,
    });
  }

  async getSchoolOngoingProgramList() {
    this.apiSchoolOngoingProgramListId = await this.apiCall({
      contentType: configJSON.dashboardContentType,
      method: configJSON.dashboardGetApiMethod,
      endPoint: configJSON.getOnGoingProgramsListEndPoint,
    });
  }

  handleUserDetails(userDetails: UserDetails) {
    this.setState({ userDetails: userDetails, userRole: userDetails.role });
  }

  handleUpcomingEvents(upcomingEvents: { data?: UpcomingEvents[], message?: string }) {
    let fetchedEvents: UpcomingEvents[] = [];
    if (upcomingEvents.message) {
      fetchedEvents = [];
    }

    if (upcomingEvents.data) {
      fetchedEvents = upcomingEvents.data;
    }

    this.setState({ upcomingEvents: fetchedEvents });
  }

  handleProgramScheduleEvents(response: { data?: ProgramScheduleEvent[], message?: string }) {
    let programScheduleEvents: ProgramScheduleEvent[] = [];
    if (response.message) {
      programScheduleEvents = [];
    }

    if (response.data) {
      programScheduleEvents = response.data;
    }

    this.setState({ events: programScheduleEvents });
  }

  handleUserProgress(progress: UserProgress) {
    this.setState({ progress: progress });
  }

  handleMindMastery(mindMastery: MindMastery[]) {
    this.setState({ mindMastery: mindMastery });
  }

  handleSuperpowers(superpower: Superpower[]) {
    this.setState({ superPowers: superpower });
  }

  handleStudentReports(reports: StudentReport[]) {
    this.setState({ reports: reports });
  }

  handleCourseStatus(data: CourseStatus) {
    const courseId = data.attributes.mind_mastery_course_id;
    const status = data.attributes.status;
    const sessionId = localStorage.getItem("upcoming_session_id");
    localStorage.removeItem("upcoming_session_id");
    if ((status === 'started' || status === 'completed') && sessionId) {
      this.goTo('SessionsListPage', { courseId, sessionId: JSON.parse(sessionId) });
    } else {
      this.goTo('MindMasteryPage');
    }
  }

  handleOngoingPrograms(ongoingPrograms: { programs: OnGoingProgramProps[] }) {
    if (ongoingPrograms.programs) {
      this.setState({ ongoingPrograms: ongoingPrograms.programs });
    }
  }

  handleTrainerYouMatter(trainerYouMatters: YouMatterProps[]) {
    this.setState({ trainerYouMatters: trainerYouMatters, sortedTrainerYouMatters: trainerYouMatters });
  }

  handleChildsOngoingPrograms(childrenDetails: ChildDetails[]) {
    this.setState({ childrenDetails: childrenDetails });
  }

  handleSchoolOngoingPrograms(schoolOngoingPrograms: SchoolOngoingProgram[]) {
    this.setState({ schoolOngoingPrograms: schoolOngoingPrograms, ongoingProgramIndex: 0 });
  }

  handleSchoolOngoingProgramList(schoolOngoingProgramList: SchoolOngoingProgramList) {
    this.setState({ schoolOngoingProgramList: { programs_list: schoolOngoingProgramList.programs_list, count: schoolOngoingProgramList.count } });
  }

  handleError(responseError: { error: string | string[] }) {
    console.log("Error occurred", responseError.error)
  }

  handleArrowNavigation(type: string) {
    const currentIndex = this.state.ongoingProgramIndex;
    if (type === "next") {
      this.setState({ ongoingProgramIndex: currentIndex + 1 });
    } else {
      this.setState({ ongoingProgramIndex: currentIndex - 1 });
    }
  };

  async handleDateChange(newDate: Value) {
    this.setState({ currentDate: newDate as Date});
    this.getUpcomingEvents(newDate as Date);
  }

  async handleSingleEvent(id: string) {
    await this.getEventDetails(id);
  }

  handleEventData(event: UpcomingEvents) {
    const dateString = event.attributes.date;
    const startTimeString = event.attributes.time.slice(1, -1).split(" - ")[0];
    const dateStartTimeString = `${dateString} ${startTimeString}`;
    const formatString = "MM-DD-YYYY h:mm a";
    const startDate = moment(dateStartTimeString, formatString).toDate();
    const day = startDate.toLocaleString("en-us", { weekday: "short" });
    const date = startDate.getDate();
    const eventName = event.attributes.name;
    const courseName = event.course_name || event.attributes.course_name;
    const eventTime = event.attributes.time.slice(1, -1);
    const organizerPic = event.attributes.organizer?.profile_photo_url;
    const organizerName = event.attributes.organizer?.name;
    return { day, date, eventName, eventTime, courseName, organizerPic, organizerName };
  }

  handleEventDetails(eventDetails: UpcomingEvents) {
    let currentEvents = this.state.events;
    const updatedEvents = currentEvents.map((item: ProgramScheduleEvent) => {
      if (item.id === eventDetails.id) {
        return { ...item, ...eventDetails.attributes };
      } else {
        return { ...item };
      }
    });
    this.setState({ events: updatedEvents });
    const selectedEvent = updatedEvents.find((e: ProgramScheduleEvent) => (e.id === eventDetails.id));
    if (selectedEvent) {
      this.setState({ selectedEvent });
      this.switchPopup();
    }
  }

  async togglePopup(eventData: UpcomingEvents) {
    const { id, type, course_id, attributes } = eventData;
    const time = attributes.time.replace("[", '').replace("]", '').split("-")[0];
    const startDateTimeString = `${attributes.date} ${time}`;
    const endDateTimeString = attributes.end_date ? `${attributes.end_date} ${time}` : null;
    const userRole = this.getUserRole();
    const eventDate = moment(startDateTimeString, "MM-DD-YYYY hh:mm A");
    const endDate = attributes.end_date ? moment(endDateTimeString, "MM-DD-YYYY hh:mm A") : null;
    const isFuture = eventDate.isAfter(moment());
    const isDateInRange = endDate ? moment().isBetween(eventDate, endDate, undefined, '[]') : true;
    const isYouMatterSubmitted = attributes.submission_status === 'submitted';

    if (type === 'upcoming_events' && !this.state.showPopup) {
      await this.handleSingleEvent(id);
      return;
    }

    if (userRole !== 'student') return;

    if (!this.handleEventValidation(isDateInRange, isFuture, eventDate)) return;

    this.handleSpecificEventType(type, id, course_id, isYouMatterSubmitted);
  }

  handleEventValidation(isDateInRange: boolean, isFuture: boolean, eventDate: moment.Moment): boolean {
    if (isFuture) {
      this.setState({ snackbar: { enable: true, message: `The event is scheduled on ${eventDate.format("DD-MM-YYYY [at] hh:mm A")}`, type: 'error' } })
      return false;
    }

    if (!isDateInRange) {
      this.setState({ snackbar: { enable: true, message: `The event has expired`, type: 'error' } });
      return false;
    }
    return true;
  }

  async handleSpecificEventType(type: string, id: string, courseId: number | undefined, isYouMatterSubmitted: boolean) {
    if (type === 'upcoming_you_matter_events') {
      if (isYouMatterSubmitted) {
        this.setState({ snackbar: { enable: true, message: `The You Matter is already submitted`, type: 'error' } });
        return;
      }
      localStorage.setItem("redirect_from", JSON.stringify({ youMatterId: id, type }));
      this.goTo('YouMatter');
    } else if (type === 'upcoming_assign_session_events') {
      localStorage.setItem("upcoming_session_id", JSON.stringify(id));
      await this.getCourseStatus(courseId);
    }
  }

  handleCreateYouMatter() {
    this.goTo("TrainerYouMatterCheck");
  }

  handleYouMatterFilterChange(value: string, key: string) {
    const { trainerYouMatterFilter } = this.state;
    this.setState({ trainerYouMatterFilter: { ...trainerYouMatterFilter, [key]: value } })
  }

  handleClearYouMatterFilter() {
    this.setState({ trainerYouMatterFilter: { course_name: "", grade: "", school: "" } });
  }

  handleChildChange(childId: string) {
    const childrenIndex = this.state.childrenDetails.findIndex((childrenOngoingProgram: ChildDetails) => childrenOngoingProgram.id === childId);
    this.setState({ activeChildIndex: childrenIndex, ongoingProgramIndex: 0 });
  }

  handleSnackbarClose() {
    this.setState({ snackbar: { enable: false, message: '', type: "info" } });
  }

  getSortedTrainerYouMatter() {
    const { trainerYouMatterFilter, trainerYouMatters } = this.state;
    const { course_name, school, grade } = trainerYouMatterFilter;
    if (course_name == "" && school == "" && grade == "") {
      return trainerYouMatters;
    }
    return trainerYouMatters.filter((trainerYouMatter: YouMatterProps) => {
      const { course_name, school, grade } = trainerYouMatter.attributes;
      const matchesCourseName = trainerYouMatterFilter.course_name ? trainerYouMatterFilter.course_name === course_name : true;
      const matchesSchool = trainerYouMatterFilter.school ? trainerYouMatterFilter.school === school : true;
      const matchesGrade = trainerYouMatterFilter.grade ? trainerYouMatterFilter.grade === grade : true;
      return matchesCourseName && matchesSchool && matchesGrade;
    });
  }

  createYouMatterMenuList(key: keyof YouMatterProps['attributes']) {
    const menuList = this.state.sortedTrainerYouMatters.map((sortedTrainerYouMatter: YouMatterProps) => {
      const { id, attributes } = sortedTrainerYouMatter;
      const value = attributes[key] as string;
      return { id, value, title: value };
    });
    const uniqueMenuMap = new Map<string, { id: string; value: string; title: string }>();
    menuList.forEach(item => {
      if (!uniqueMenuMap.has(item.value)) {
        uniqueMenuMap.set(item.value, item);
      }
    });
    const uniqueMenuList = Array.from(uniqueMenuMap.values());
    return uniqueMenuList;
  }

  getUserRole() {
    const userRole = this.state.userRole || localStorage.getItem("role");
    return userRole?.toLowerCase();
  }

  formatDate(day: number) {
    const shortDays = ["S", "M", "T", "W", "T", "F", "S"];
    return shortDays[day];
  }

  switchPopup() {
    this.setState({ showPopup: !this.state.showPopup });
  };

  goTo(module: string, params: Object = {}) {
    this.props.navigation.navigate(module, { ...params });
  };
  // Customizable Area End
}